<template>
	<v-app class="fs_main login">
		<v-container>
			<img src="/img/logo.png" class="logo" />
			<div>
				<div class="registerForm">
					<v-text-field
						v-model="loginId"
						background-color="white"
						clearable
						filled
						label="メールアドレス"
						class="textfield"
						:rules="[rules.required]"
					></v-text-field>
					<v-text-field
						label="パスワード"
						class="textfield"
						v-model="loginPassword"
						background-color="white"
						clearable
						:append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
						:rules="[rules.min, rules.required]"
						:type="passwordShow ? 'text' : 'password'"
						hint="パスワードは8文字以上"
						filled
						@click:append="passwordShow = !passwordShow"
					></v-text-field>
					<v-text-field
						class="textfield"
						v-model="userName"
						:rules="[rules.required]"
						background-color="white"
						clearable
						filled
						label="名前"
					></v-text-field>
					<div class="registerButton" @click="clickEmailRegister()">
						<v-btn depressed>新規登録</v-btn>
					</div>
				</div>
			</div>
		</v-container>
	</v-app>
</template>

<script>
import md5 from "js-md5";
import axios from "axios";

export default {
	created() {
		axios.defaults.headers.common["Authorization"] =
			"Bearer " + this.$store.state.auth.data.token;
	},
	mounted() {
		document.querySelector('.v-application').setAttribute("style", "min-height: 100vh !important;");
	},
	methods: {
		clickEmailRegister() {
			if (this.loginId && this.userName && this.loginPassword.length >= 8) {
				var reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
				if (!reg.test(this.loginId)) {
					alert("メールアドレスが不正です");
					return;
				}
				this.showLoading();
				const params = {
					loginId: this.loginId,
					loginPassword: md5(this.loginPassword),
					userName: this.userName
				};
				axios.post(
					"https://" +
						this.config.defaultOriginString +
						"/api/mail-register",
					params
				)
				.then((res) => {
					this.hideLoading();
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						console.log("Success: " + JSON.stringify(res.data));
						alert("登録ありがとうございました。メールをお送りしましたので、メールに記載中のURLをクリックしてアクティベーションを完了して下さい");
					} else {
						console.log("Fail: " + JSON.stringify(res));
						alert("メールアドレスが既に使用されています");
					}
				})
				.catch((error) => {
					this.hideLoading();
					console.log(error);
					alert("原因不明のエラーです");
				});
			} else {
				alert("全てのフィールドに入力して下さい");
			}
		},
		clickLineImage() {
			location.href =
				"https://" + this.config.defaultOriginString + "/api/line-authorize";
		},
		clickAzureImage() {
			location.href =
				"https://" + this.config.defaultOriginString + "/api/azure-authorize";
		},
		showLoading: function () {
			this.$emit("loadShowHide", true);
		},
		hideLoading: function () {
			this.$emit("loadShowHide", false);
		},
	},
	data() {
		return {
			loginId: "",
			loginPassword: "",
			userName: "",
			passwordShow: false,
			rules: {
				required: v => !!v || '必須項目',
				min: v => v.length >= 8 || "パスワードは8文字以上",
			},
		};
	},
};
</script>
